import React from "react"
import SpringTest from '../components/spring-test'
import Layout from "../components/layout"
import SEO from "../components/seo"

const TestPage = () => (
  	<Layout>
    	<SEO title="Clients and projects" />
    	<div>
    	<p>This is above the div</p>
    	<SpringTest />
    	<p>This is below the div</p>
    	</div>
	</Layout>
    )
export default TestPage